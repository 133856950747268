export default {
    registrationUseCheckbox: true,
    registrationEmailVerifyEnable: true,
    dfpcEnabled: true,
    demoMode: false,
    leaderboard: false,
    kyc: true,
    disableWWW: true,
    dgaPragmatic: false,
    empPopupEnabled: false,
    disableThemeSwitcher: true,
    enableVerifyEmailByIbiza: true,
    enableWinnersMock: false,
    enableNewBonuses: true,
    enableLimits: true,
    promoCodeForSingUp: false,
    enableStoriesVerification: true,
    enableSelfExclusion: true,
    enableFastTrackFirebase: false,
    homeBannerLikeStories: true,
    depositInsuranceBonus: true,
    enabledLivespins: true,
    freshchatCustomWidgetId: false,
    enabledQuestsNew: true,
    enableGlobalEvent: false,
};
